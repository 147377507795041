"use strict";

// Popup

$(function () {
    // hide popup when user clicks on close button or if user clicks anywhere outside the container
    $('.overlay-bg').click(function () {
        $('.overlay-bg, .overlay-content').hide(); // hide the overlay
    });

    // hide the popup when user presses the esc key
    $(document).keyup(function (e) {
        if (e.keyCode === 27) { // if user presses esc key
            $('.overlay-bg, .overlay-content').hide(); //hide the overlay
        }
    });

    // aj.activatePagelinks();
});